<template>
  <div class="netsearch">
    <navigation-bar
      :placeholder="$route.params.val ? $route.params.val : val"
      @search="handleSearch"
    >
      <template #nleft>
        <van-icon
          name="arrow-left"
          @click="onLeft"
          :style="{ 'margin-right': '6px' }"
        />
      </template>
      <template #nclear>
        <img src="~@image/close.png" alt="" />
      </template>
    </navigation-bar>
    <div class="netsearch-rlt">搜索结果&nbsp;({{ schData.length }})</div>
    <div
      :class="[
        'netsearch-item',
        !schData.length ? 'netsearch-item-default' : '',
      ]"
    >
      <div class="netsearch-item-def" v-if="!schData.length">
        <span>暂无搜索结果，换个词汇试试看吧</span>
      </div>
      <div
        v-for="(item, index) in schData"
        :key="index"
        class="netfile"
        @click="openFile(item)"
      >
        <div class="file-item">
          <div class="file-item-image">
            <img
          src="~@image/net-file.png"
          alt=""
          v-if="item.type == 1 && item.isDeleted != -1"
        />
        <img
          src="~@image/net-file-system.png"
          alt=""
          v-if="item.type == 1 && item.isDeleted == -1"
        />
        <img
          src="~@image/net-doc-x.png"
          alt=""
          v-if="item.filetype == 1 || item.filetype == 5"
        />
        <img src="~@image/net-txt.png" alt="" v-if="item.filetype == 2" />
        <img
          src="~@image/net-excel.png"
          alt=""
          v-if="item.filetype == 3 || item.filetype == 7"
        />
        <img
          src="~@image/net-presentation.png"
          alt=""
          v-if="item.filetype == 0 || item.filetype == 6"
        />
        <img src="~@image/net-pdf.png" alt="" v-if="item.filetype == 4" />
        <img src="~@image/net-img.png" alt="" v-if="item.filetype == 63" />
          </div>
          <div class="file-item-main">
            <h1 class="tit">{{ item.filename }}</h1>
            <div class="detail">
              <span class="detail-time">{{ item.updateTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "./NavigationBar.vue";
import { searchFile } from "@api/network.js";
import qs from "qs";
export default {
  name: "netfilesearch",
  data() {
    return {
      //搜索结果数据
      // schData: this.$route.params.data ? this.$route.params.data : [],
      schData: [],
      //关键字
      val: "",
    };
  },
  components: {
    NavigationBar,
  },
  created() {
    this.val = this.$route.params.val ? this.$route.params.val : "";
    this.handleSearch(this.val);
  },
  methods: {
    //返回
    onLeft() {
      this.$router.go(-1);
    },
    //搜索
    handleSearch(val) {
      this.val = val;
      let params = {
        condition: val,
      };
      searchFile(qs.stringify(params)).then((res) => {
        if (res.code == 0) {
          this.schData = res.data;
        }
      });
    },
    //打开文件
    openFile(file) {
      this.$router.push({
        name: "fileword",
        params: {
          id: file.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-icon {
  color: #666;
  width: 30px;
}
.netsearch {
  font-family: PingFang SC;
  .NavigationBar-searchbox-code {
    display: none;
  }
  #search {
    &::placeholder {
      color: #333;
    }
  }
  .close {
    img {
      width: 16px;
      height: 16px;
    }
  }
  &-rlt {
    padding: 20px 15px;
    font-size: 12px;
    font-weight: 500;
    color: #999999;
  }
  &-item {
    overflow-y:scroll;
    height:calc(100vh - 1.92rem);
    &-def {
      display:flex;
      flex-direction:column-reverse;
      height:170px;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      color: #999999;
      background:url("~@image/net-sch-default.png") center no-repeat;
      background-size:139px 112px; 
    }
    .netfile {
      padding: 10px 15px;
      overflow-y: scroll;
      .file-item {
        display: flex;
        flex-wrap: nowrap;
        // margin-top: 15px;
        &-image {
          img {
            width: 52.5px;
            height: 52.5px;
          }
        }
        &-main {
          position: relative;
          flex: 1;
          margin-left: 15px;
          padding-top: 3.5px;
          .tit {
            width: 230px;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .detail {
            margin-top: 8px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
            &-count {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
  &-item-default {
    display:flex;
    justify-content:center;
    height: calc(100vh - 96px);
    padding-top:103px;

  }
}
</style>